import { useContext, useEffect, useState } from 'react'
import MainPageWrapper from '../../global/components/MainPageWrapper'
import FuturePercentBooked from '../components/future-percent-booked/FuturePercentBooked'
import { fetchData } from '../../global/utils/fetch'
import { getFuturePercentBookedData } from '../components/future-percent-booked/api'
import styles from './styles.module.css'
import { CircularProgress } from '@mui/material'
import hasPermission from '../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../global/constants/permissions'
import { UserContext } from '../../base/components/base-container/BaseContainer'
import { DASHBOARD_KEYS } from '../../global/constants/dashboards'
import TabChooser from './TabChooser'
import SchedulingPerformance from '../components/scheduling-performance/SchedulingPerformance'
import { getSchedulingPerformanceData } from '../components/scheduling-performance/api'
import DashboardMainHeader from '../components/header/DashBoardsMainHeader'

interface IDashboardsMainProps {
  tabType?: DASHBOARD_KEYS
}

function DashboardsMain({ tabType }: IDashboardsMainProps) {
  const { user } = useContext(UserContext)
  const [isCsvLoading, setIsCsvLoading] = useState<boolean>(false)
  const [queryDate, setQueryDate] = useState<Date>(new Date())
  const [table, setTable] = useState<JSX.Element | null>(null)

  useEffect(() => {
    switch (tabType) {
      case DASHBOARD_KEYS.schedulingPerformance:
        if (
          hasPermission(user).allow(
            PERMISSIONS.DASHBOARDS.CATEGORY,
            PERMISSIONS.DASHBOARDS.ENTRIES.VIEW_SCHEDULING_PERFORMANCE.NAME,
          )
        ) {
          setTable(<SchedulingPerformance date={queryDate} />)
        } else {
          setTable(null)
        }
        break
      case DASHBOARD_KEYS.futurePercentBooked:
        if (
          hasPermission(user).allow(
            PERMISSIONS.DASHBOARDS.CATEGORY,
            PERMISSIONS.DASHBOARDS.ENTRIES.VIEW_FUTURE_PERCENT_BOOKED.NAME,
          )
        ) {
          setTable(<FuturePercentBooked date={queryDate} />)
        } else {
          setTable(null)
        }
        break
      default:
        setTable(null)
    }
  }, [queryDate, tabType])

  async function downloadCsv() {
    try {
      setIsCsvLoading(true)
      let csv
      switch (tabType) {
        case DASHBOARD_KEYS.schedulingPerformance:
          csv = await fetchData<any>(
            getSchedulingPerformanceData(queryDate, true),
          )
          break
        case DASHBOARD_KEYS.futurePercentBooked:
          csv = await fetchData<any>(
            getFuturePercentBookedData(queryDate, true),
          )
          break
      }
      const blob = new Blob([csv.csv], { type: 'text/csv;charset=utf-8;' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      const errorMessage = `Error downloading CSV: ${error}`
      alert(errorMessage)
      console.error(errorMessage)
    } finally {
      setIsCsvLoading(false)
    }
  }

  return (
    <MainPageWrapper
      title='Dashboards'
      titleFields={
        <DashboardMainHeader
          queryDate={queryDate}
          setQueryDate={setQueryDate}
        />
      }
    >
      <TabChooser />
      <div className={styles.link}>
        <button
          className={styles.navLink}
          onClick={downloadCsv}
          disabled={
            !hasPermission(user).allow(
              PERMISSIONS.DASHBOARDS.CATEGORY,
              PERMISSIONS.DASHBOARDS.ENTRIES.DOWNLOAD_CSV.NAME,
            )
          }
        >
          Download CSV
        </button>
        {isCsvLoading && (
          <CircularProgress
            className={styles.loadingCircle}
            size={10}
          />
        )}
        <div className={styles.tableContainer}>
          <div>{table}</div>
        </div>
      </div>
    </MainPageWrapper>
  )
}

export default DashboardsMain
