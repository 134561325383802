import { IOrderService } from '../../../../../../../app/entities/OrderService'
import { AvailabilityCalendarSlot } from '../../../../../../../app/types/scheduling'
import { ISchedulerStateProps } from '../container-modal/initialData'

export function getTireSize(vehicle: string) {
  const encoded = encodeURIComponent(vehicle)
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/vehicles/tire-sizes?vehicle=${encoded}`,
  }
}

export interface UpdateOrderStatusToScheduledOptions {
  availabilityCalendarSlot?: AvailabilityCalendarSlot
  reason?: string
  username?: string
}

export function updateOrderStatusToScheduled(
  orderObjectId: string,
  options: UpdateOrderStatusToScheduledOptions = {},
) {
  const { availabilityCalendarSlot, reason, username } = options

  const params = new URLSearchParams()
  if (reason) params.append('reason', reason)
  if (username) params.append('username', username)

  return {
    method: 'POST',
    url:
      `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/status/scheduled/no-auth` +
      (params.toString() ? `?${params.toString()}` : ''),
    body: availabilityCalendarSlot ? availabilityCalendarSlot : {},
    returnRaw: true,
  }
}

export function createTireCombination(body: any) {
  return {
    method: 'POST',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/tires_combination`,
  }
}
export function updateTireCombination(body: any) {
  return {
    method: 'PUT',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/tires_combination`,
  }
}

export function setTireCombination(
  body: any,
  orderObjectId: string,
  orderVehicleId: string,
) {
  return {
    method: 'POST',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/vehicles/${orderVehicleId}/tires`,
  }
}
export function updateVehicleMileage(objectId: string, mileage: number) {
  const body = {
    objectId,
    mileage,
  }
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/order_vehicles`,
    body: body,
  }
}
export function createOrderService(body: any) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/order_service`,
    body,
  }
}
export function setOrderServices(
  orderObjectId: string | undefined,
  orderVehicleObjectId: string,
  orderServiceId: string,
) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/vehicles/${orderVehicleObjectId}/order-services`,
    body: [orderServiceId],
  }
}

export function handleGenericItems(
  orderObjectId: string,
  genericItems: IOrderService[],
) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/generic-items`,
    body: genericItems,
  }
}

export function getPsiTorqueSuggestions(body: {
  year: string
  make: string
  model: string
  variant: string
  frontTireWidth: string
  frontTireAspectRatio: string
  frontTireDiameter: string
  rearTireWidth: string
  rearTireAspectRatio: string
  rearTireDiameter: string
}) {
  const query = new URLSearchParams(body)

  return {
    method: 'GET',
    url: `${
      process.env.REACT_APP_ORIGIN
    }/psi-torque/suggestions?${query.toString()}`,
  }
}

export function getLocationServiceByServiceForOrder(
  schedulerState: ISchedulerStateProps,
) {
  const { service: serviceObjectId, orderId: orderObjectId } = schedulerState
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/location_service/by-service-for-order?orderObjectId=${orderObjectId}&serviceObjectId=${serviceObjectId}`,
  }
}
