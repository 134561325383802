import { useEffect, useState } from 'react'
import styles from './LineItemAllocator.module.css'
import PrimaryCheckbox from '../checkbox/Checkbox'
import { TextField } from '@mui/material'
import { LineItem } from '../../../../../app/types/line-item'
import { IInvoice } from '../../../../../app/entities/Invoice'
import isInvoiceRefund from '../../utils/invoice/type/is-invoice-refund'
import { STATUSES } from '../../constants/invoice'
import { IDiscount } from '../../../../../app/entities/Discount'
import { IOrderService } from '../../../../../app/entities/OrderService'
import DecimalWrapper from '../../utils/decimal-wrapper/DecimalWrapper'

// these are the statuses that are considered active for refunding
// in the sense that the amounts on the invoices should be considered
// for refunding, leaving out REFUND_CANCELLED and VOIDED
const ACTIVE_REFUND_STATUSES = [
  STATUSES.REFUND_REQUESTED,
  STATUSES.SUBMITTED_FOR_SETTLEMENT,
  STATUSES.SETTLED,
]
interface LineItemAllocatorProps {
  lineItems: LineItem[]
  discounts: IDiscount[]
  invoices: IInvoice[]
  orderServices: IOrderService[]
  onAllocationsChange: (allocations: Record<string, number>) => void
  helperText?: string
  requiredPercentage?: number
  discountForEdit?: IDiscount
}

function LineItemAllocator({
  lineItems,
  invoices,
  discounts,
  orderServices,
  onAllocationsChange,
  helperText,
  requiredPercentage,
  discountForEdit,
}: LineItemAllocatorProps) {
  const editingADiscount = discountForEdit?.objectId !== undefined
  const initialAllocations = discountForEdit?.allocations
  const [allocations, setAllocations] = useState<Record<string, number>>({})
  const [selectedLineItems, setSelectedLineItems] = useState<LineItem[]>([])

  useEffect(() => {
    if (initialAllocations) {
      const initialSelectedLineItems = lineItems.filter(
        (li) =>
          initialAllocations &&
          initialAllocations[li.id as string] !== undefined,
      )
      setSelectedLineItems(initialSelectedLineItems)

      setAllocations(initialAllocations)
    }
  }, [initialAllocations])

  const refundInvoices = invoices
    .filter((invoice) => isInvoiceRefund(invoice))
    .filter(({ status }) => ACTIVE_REFUND_STATUSES.includes(status as STATUSES))

  function createAllocationsMap(
    entities: { allocations: Record<string, number>; objectId: string }[],
  ) {
    return entities
      .filter((entity) => entity.objectId !== discountForEdit?.objectId)
      .reduce((acc, entity) => {
        const { allocations } = entity
        Object.keys(allocations || {}).forEach((key) => {
          if (!acc[key]) {
            acc[key] = 0
          }

          acc[key] += allocations[key]
        })
        return acc
      }, {} as Record<string, number>)
  }

  const previousRefundAllocationsMap = createAllocationsMap(refundInvoices)
  const previousDiscountAllocationsMap = createAllocationsMap(discounts)

  /* This function is replicated in
   * app/eworkflows/promocode/apply-promo-code-to-order/apply-promo-code-to-order.ts.
   * Any modifications should be replicated there.
   */
  function getMaximumAmountToDiscountOrRefund(orderServiceObjectId: string) {
    const previousRefundAmount =
      previousRefundAllocationsMap[orderServiceObjectId] || 0
    const previousDiscountAmount =
      previousDiscountAllocationsMap[orderServiceObjectId] || 0

    const currentLineItemAmount = getCurrentLineItemAmount(orderServiceObjectId)

    let maximumAmount = DecimalWrapper.sub(
      2,
      currentLineItemAmount,
      previousRefundAmount,
      previousDiscountAmount,
    )

    return maximumAmount > 0 ? maximumAmount : 0
  }

  /* This function is replicated in
   * app/eworkflows/promocode/apply-promo-code-to-order/apply-promo-code-to-order.ts.
   * Any modifications should be replicated there.
   */
  function getCurrentLineItemAmount(orderServiceObjectId: string) {
    const lineItem = lineItems.find((li) => li.id === orderServiceObjectId)
    const { unit_price, quantity, combined_tax_rate } = lineItem as LineItem

    const netPrice = (unit_price as number) * (quantity as number)
    const totalOriginalAmount = DecimalWrapper.mul(
      2,
      netPrice,
      1 + (combined_tax_rate || 0),
    )

    return totalOriginalAmount
  }

  const enabledLineItems = lineItems.filter((li) => {
    const maximumRefundAmount = getMaximumAmountToDiscountOrRefund(
      li.id as string,
    )
    return maximumRefundAmount > 0
  })

  /* This function is replicated in
   * app/eworkflows/promocode/apply-promo-code-to-order/apply-promo-code-to-order.ts.
   * Any modifications should be replicated there.
   */
  function handleSelectedOrderServiceChanges() {
    const newlyDeselectedLineItems = Object.keys(allocations)
      .filter((id) => !selectedLineItems.some((li) => li.id === id))
      .map((id) => lineItems.find((li) => li.id === id))

    const allocationsClone = { ...allocations }

    selectedLineItems.forEach((li) => {
      // if the required percentage is set, then we should calculate the maximum amount
      // based on the original amount of the line item and the required percentage, otherwise,
      // we should calculate the maximum amount based on the previous discount amounts
      const shouldForceAllocationUpdate =
        allocations[li.id as string] === undefined ||
        requiredPercentage !== undefined

      if (initialAllocations?.[li.id as string] !== undefined) {
        allocationsClone[li.id as string] = initialAllocations[li.id as string]
      } else if (shouldForceAllocationUpdate) {
        const maximumAmount =
          requiredPercentage === undefined
            ? getMaximumAmountToDiscountOrRefund(li.id || '')
            : getCurrentLineItemAmount(li.id || '') * (requiredPercentage / 100)

        const rounded = Math.round(maximumAmount * 100) / 100
        allocationsClone[li.id as string] = rounded
      }
    })

    newlyDeselectedLineItems.forEach((li) => {
      delete allocationsClone[li?.id as string]
    })

    setAllocations(allocationsClone)
  }

  function handleAllocationsChanges() {
    if (onAllocationsChange) {
      onAllocationsChange(allocations)
    }
  }

  function handleRequiredPercentageChanges() {
    if (requiredPercentage !== undefined) {
      setSelectedLineItems(enabledLineItems)
    } else if (!editingADiscount) {
      setSelectedLineItems([])
    }
  }

  useEffect(handleSelectedOrderServiceChanges, [
    selectedLineItems,
    requiredPercentage,
  ])

  useEffect(handleAllocationsChanges, [allocations])

  useEffect(handleRequiredPercentageChanges, [requiredPercentage])

  let selectAllButtonCopy = 'Select All'

  if (requiredPercentage !== undefined) {
    helperText =
      helperText ??
      `Because a "percentage" refund is selected, all items are required to be discounted.`
  } else if (enabledLineItems.length === 0) {
    selectAllButtonCopy = 'All Items Refunded'
  } else if (selectedLineItems.length === enabledLineItems.length) {
    selectAllButtonCopy = 'Deselect All'
  }

  return (
    <div className={styles.lineItemAllocatorMain}>
      <div className={styles.lineItemAllocatorContainer}>
        <div className={styles.lineItemAllocatorHeader}>
          <div className={styles.helperText}>
            {helperText ?? 'Select the desired line items and amounts.'}
          </div>
        </div>
        <div className={styles.lineItemAllocatorBody}>
          <div className={styles.additionalActions}>
            <div className={styles.lineItemRow}>
              <div className={styles.checkboxContainer}>
                <PrimaryCheckbox
                  checked={
                    enabledLineItems.length > 0 &&
                    selectedLineItems.length === enabledLineItems.length
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedLineItems(enabledLineItems)
                    } else {
                      setSelectedLineItems([])
                    }
                  }}
                  disabled={
                    requiredPercentage !== undefined ||
                    enabledLineItems.length === 0
                  }
                />
                <span style={{ fontWeight: 'bold' }}>
                  {selectAllButtonCopy}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.lineItemsHolder}>
            {lineItems.map((lineItem) => {
              const { id: orderServiceObjectId } = lineItem
              const selected = selectedLineItems.includes(lineItem)
              const maximumRefundAmount = getMaximumAmountToDiscountOrRefund(
                orderServiceObjectId as string,
              )
              const fullAmountHasBeenAllocated = maximumRefundAmount === 0
              const orderService = orderServices.find(
                (os) => os.objectId === orderServiceObjectId,
              )
              const displayName =
                lineItem.description +
                (orderService?.description
                  ? ` (${orderService.description})`
                  : '')

              return (
                <div
                  key={orderServiceObjectId}
                  className={styles.lineItemRow}
                >
                  <div className={styles.checkboxContainer}>
                    <PrimaryCheckbox
                      checked={selected}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedLineItems([...selectedLineItems, lineItem])
                        } else {
                          setSelectedLineItems(
                            selectedLineItems.filter(
                              (selectedOrderService) =>
                                selectedOrderService !== lineItem,
                            ),
                          )
                        }
                      }}
                      disabled={
                        requiredPercentage !== undefined ||
                        fullAmountHasBeenAllocated
                      }
                    />
                    <span>{displayName}</span>
                    {fullAmountHasBeenAllocated && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                          marginLeft: '.5rem',
                          fontWeight: 600,
                        }}
                      >
                        (Fully Refunded/Discounted)
                      </div>
                    )}
                  </div>
                  {selectedLineItems.includes(lineItem) && (
                    <TextField
                      style={{
                        justifySelf: 'end',
                        backgroundColor: 'white',
                        width: '7rem',
                      }}
                      type='number'
                      value={allocations[orderServiceObjectId as string] ?? ''}
                      placeholder='Amount to allocate'
                      disabled={requiredPercentage !== undefined}
                      onChange={(e) => {
                        const string = e.target.value
                        const shouldIgnore = ['-', '+', ''].includes(string)

                        if (shouldIgnore) {
                          return
                        }

                        let value = parseFloat(string)

                        if (value < 0) {
                          value = 0
                        } else if (value > maximumRefundAmount) {
                          value = maximumRefundAmount
                        }

                        setAllocations({
                          ...allocations,
                          [orderServiceObjectId as string]: value,
                        })
                      }}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LineItemAllocator
