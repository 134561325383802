export enum DASHBOARD_KEYS {
  futurePercentBooked = 'futurePercentBooked',
  schedulingPerformance = 'schedulingPerformance',
}

export const DASHBOARD_NAMES: { [section in DASHBOARD_KEYS]: string } = {
  futurePercentBooked: '14-day Future Percent Booked',
  schedulingPerformance: 'Scheduling Performance',
}

export const DASHBOARD_PATHS: { [section in DASHBOARD_KEYS]: string } = {
  futurePercentBooked: '/dashboards/future-percent-booked',
  schedulingPerformance: '/dashboards/scheduling-performance',
}
