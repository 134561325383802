import { useContext, useState } from 'react'
import { IBatteryData } from '../../../../../../../app/entities/BatteryData'
import { VanContext } from '../../../../../global/context/van-context/VanContext'
// import { BatteryDataTable } from './BatteryDataTable'
import styles from './styles.module.css'
import { formatDateTime } from '../../../../../global/utils/date/hmm-mm-dd-yyyy'
import DownloadReportsModal from '../../../../../reports/components/reports/download-reports-modal/DownloadReportsModal'
import { DOWNLOAD_REPORTS_CATEGORY } from '../../../../../global/constants/reports'

export function BatteryData() {
  const [isOpen, setIsOpen] = useState(false)
  const { vanData } = useContext(VanContext)
  const batteryData = (vanData.currentBatteryData ?? []) as IBatteryData[]
  function handleOpen() {
    setIsOpen(true)
  }
  function handleClose() {
    setIsOpen(false)
  }
  function getBatteryDataDisplay(batteryData: IBatteryData[]) {
    if (batteryData.length === 0) {
      return 'No battery data available'
    }
    return batteryData.map((data, index) => {
      const timestamp = formatDateTime(new Date(data.created))
      const stateOfCharge = data.stateOfCharge
      const voltage = data.voltage
      const current = data.current
      const capacity = data.capacity
      const temperature = data.temperature
      return (
        <div key={index}>
          <div>
            <span style={{ fontStyle: 'italic' }}>{data.batteryName}</span>
            {', '}
            {timestamp}
          </div>
          <div style={{ paddingLeft: '20px' }}>
            {stateOfCharge}%, {voltage}V, {current}A, {capacity}aH,{' '}
            {temperature}°F
          </div>
        </div>
      )
    })
  }
  return (
    <>
      {/* //todo FRON-1045 don't use the Download reports modal
      this is a temporary solution until we build out the visual upgrades
      that Miles wants longterm */}
      <DownloadReportsModal
        isOpen={isOpen}
        handleClosed={handleClose}
        category={DOWNLOAD_REPORTS_CATEGORY.BATTERY_DATA}
        vanObjectId={vanData.objectId}
      />
      {/* <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <Box className={styles.batteryDataModal}>
          <div className={styles.tableContainer}>
            <BatteryDataTable />
            
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <SecondaryButton
                buttonName='Close'
                onClick={handleClose}
              />
            </div>
          </div>
        </Box>
      </Modal> */}
      <div className={styles.subsectionTitle}>Battery Data</div>
      <button
        className={styles.stateOfChargeContainer}
        onClick={handleOpen}
      >
        {getBatteryDataDisplay(batteryData)}
      </button>
    </>
  )
}
