import { useState, useEffect, useMemo, useContext, Fragment } from 'react'
import styles from './styles.module.css'
import { fetchData } from '../../../global/utils/fetch'
import { getFuturePercentBookedData } from './api'
import moment from 'moment'
import { COLUMN_DEFINITIONS } from '../scheduling-performance/helpers/column-definitions'
import { ToastNotificationContext } from '../../../global/context/toast-context/ToastNotificationContext'
import { formatDateYyyyMmDd } from '../../../global/utils/date/date-utils-yyyy-mm-dd'

interface D2ACalculations {
  minimum: number
  thirtyDayAverage: number
  maximum: number
}
interface FuturePercentBookedLocationData {
  d2a: D2ACalculations
  fourteenDayJobCapacities: number[]
  percentBookedArray: string[]
  fourteenDayWorkOrderCounts: number[]
}

interface FuturePercentBookedData {
  futurePercentBookedLocation: Record<string, FuturePercentBookedLocationData>
  totalData: (number | string)[]
}

function FuturePercentBooked({ date }: { date: Date }) {
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [tableState, setTableState] = useState<FuturePercentBookedData | null>(
    null,
  )

  const [content, setContent] = useState<JSX.Element | null>(null)
  const [isTableLoading, setIsTableLoading] = useState(true)

  const today = moment(date).utc().startOf('day')

  useEffect(() => {
    if (isTableLoading || !tableState) {
      setContent(
        <div className={styles.loading}>
          Loading... (may take up to a minute to finish calculating)
        </div>,
      )
    } else {
      setContent(
        <div
          className={styles.tableWrapperContainer}
          key='table'
        >
          <table className={styles.futurePercentBookedTable}>
            <thead>
              <tr key='header'>
                <th
                  className={`${styles.headerCell} ${styles.stickyHeaderOne}`}
                  key={'location'}
                ></th>
                <th
                  className={`${styles.headerCell} ${styles.stickyHeaderTwo}`}
                  key={'d2a'}
                >
                  D2A
                </th>
                <th
                  className={`${styles.headerCell} ${styles.stickyHeaderTwo}`}
                  key={'workOrders'}
                ></th>
                {Array.from({ length: 14 }, (_, i) => i + 1).map(
                  (i: number) => (
                    <th
                      className={styles.headerCell}
                      key={`day-${i}`}
                    >{`(${i}) ${today
                      .clone()
                      .add(i, 'day')
                      .format('M/D')}`}</th>
                  ),
                )}
                <th
                  className={styles.headerCell}
                  key={'7day%'}
                >
                  7day%
                </th>
                <th
                  className={styles.headerCell}
                  key={'10day%'}
                >
                  10day%
                </th>
                <th
                  className={styles.headerCell}
                  key={'14day%'}
                >
                  14day%
                </th>
              </tr>
            </thead>
            <tbody>{renderTableRows}</tbody>
          </table>
        </div>,
      )
    }
  }, [tableState, isTableLoading])

  useEffect(() => {
    fetchDashboardData()
  }, [date])

  async function fetchDashboardData() {
    try {
      setIsTableLoading(true)
      const data = await fetchData<FuturePercentBookedData>(
        getFuturePercentBookedData(date),
      )
      setTableState(data)
    } catch (err) {
      console.error(err)
      setTableState(null)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `There is no data for ${formatDateYyyyMmDd(date)}`,
        severity: 'error',
      })
    } finally {
      setIsTableLoading(false)
    }
  }

  const renderTableRows = useMemo(() => {
    if (!tableState) return <></>
    const locationData = tableState.futurePercentBookedLocation
    const [totalD2A, ...totalPercentBooked] = tableState.totalData
    const locationKeys = Object.keys(locationData)
    const rows = locationKeys.sort().map((locationName, locationIdx) => {
      const data: FuturePercentBookedLocationData = locationData[locationName]

      return (
        <Fragment key={`${locationName}-${locationIdx}-wrapper`}>
          <tr
            className={styles.row}
            key={`${locationName}-${locationIdx}`}
          >
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowOne}`}
              key={`locationName-${locationIdx}`}
            >
              {locationName}
            </td>
            <td
              className={`${styles.highlightedCellWithToolTip} ${styles.stickyRowTwo}`}
              data-tooltip={
                COLUMN_DEFINITIONS.FUTURE_PERCENT_BOOKED.d2a.minimum
              }
              key={`d2a-${locationIdx}`}
            >
              {`Min: ${data.d2a.minimum}`}
            </td>
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowTwo} ${styles.test}`}
              key={`workOrders-${locationIdx}`}
            >
              Work Orders
            </td>
            {data.fourteenDayWorkOrderCounts.map((workOrder, idx) => (
              <td key={`work-order-${locationName}-${idx}`}>{workOrder}</td>
            ))}
          </tr>
          <tr
            className={styles.row}
            key={`${locationName}-2-row`}
          >
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowOne}`}
              key={`empty-${locationIdx}`}
            ></td>
            <td
              className={`${styles.highlightedCellWithToolTip} ${styles.stickyRowTwo}`}
              data-tooltip={
                COLUMN_DEFINITIONS.FUTURE_PERCENT_BOOKED.d2a.average
              }
              key={`average-${locationIdx}`}
            >
              {`Avg: ${data.d2a.thirtyDayAverage}`}
            </td>
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowTwo} ${styles.test}`}
              key={`availableSlots-${locationIdx}`}
            >
              Available Slots
            </td>
            {data.fourteenDayJobCapacities.map((slot, idx) => (
              <td key={`slot-${locationIdx}-${idx}`}>{slot}</td>
            ))}
          </tr>
          <tr
            className={styles.row}
            key={`${locationName}-3-row`}
          >
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowOne}`}
              key={`empty-2-${locationIdx}`}
            ></td>
            <td
              className={`${styles.highlightedCellWithToolTip} ${styles.stickyRowTwo}`}
              data-tooltip={
                COLUMN_DEFINITIONS.FUTURE_PERCENT_BOOKED.d2a.maximum
              }
              key={`maximum-${locationIdx}`}
            >
              {`Max: ${data.d2a.maximum}`}
            </td>
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowTwo} ${styles.test}`}
              key={`percentBooked-${locationIdx}`}
            >
              %Booked
            </td>
            {data.percentBookedArray.map((percent, idx) => (
              <td key={`percent-${locationIdx}-${idx}`}>{percent}</td>
            ))}
          </tr>
        </Fragment>
      )
    })

    rows.push(
      <Fragment key={`total-row-${locationKeys.length}`}>
        <tr
          className={styles.row}
          key={'total'}
        >
          <td
            className={`${styles.highlightedCell} ${styles.stickyRowOne}`}
            key={'total-d'}
          >
            TRMI Total
          </td>
          <td
            className={`${styles.highlightedCell} ${styles.stickyRowTwo}`}
            key={'totalD2A'}
          >
            {totalD2A}
          </td>
          <td
            className={`${styles.highlightedCell} ${styles.stickyRowTwo}`}
            key={'totalWorkOrders'}
          >
            %Booked
          </td>
          {totalPercentBooked.map((percent, idx) => (
            <td key={`percent-${idx}`}>{percent}</td>
          ))}
        </tr>
      </Fragment>,
    )

    return rows
  }, [tableState])

  return content
}

export default FuturePercentBooked
