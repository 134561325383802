import { IDtcInvoice } from '../../../../../../../../../bi/entities/DtcInvoice'
import BasicFilter from '../../../../../../../global/components/table/helpers/BasicFilter'
import { BiReportModalDisplayModes } from '../../../../../../../global/constants/bi-reports'

function generateDtcInvoiceColumns(
  onColumnFilterUpdate: any,
  retrieveTableData: Function,
  generateTextField: Function,
  mode: BiReportModalDisplayModes,
  selectedRowIdx: number,
  handleChange: Function,
) {
  return [
    {
      Header: 'Posting Date',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ postingDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { postingDate } = data
        return generateTextField(
          'postingDate',
          postingDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Retail Store Id',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ retailStoreId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { retailStoreId } = data
        return generateTextField(
          'retailStoreId',
          retailStoreId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Trans Number',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ transNumber: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { transNumber } = data
        return generateTextField(
          'transNumber',
          transNumber?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Mtart',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ mtart: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { mtart } = data
        return generateTextField(
          'mtart',
          mtart,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Matnr',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ matnr: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { matnr } = data
        return generateTextField(
          'matnr',
          matnr?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Maktx',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ maktx: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { maktx } = data
        return generateTextField(
          'maktx',
          maktx,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'zzCustFnme',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ zzCustFnme: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { zzCustFnme } = data
        return generateTextField(
          'zzCustFnme',
          zzCustFnme?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'zzCustLnme',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ zzCustLnme: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { zzCustLnme } = data
        return generateTextField(
          'zzCustLnme',
          zzCustLnme?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'zzAddr1',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ zzAddr1: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { zzAddr1 } = data
        return generateTextField(
          'zzAddr1',
          zzAddr1,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'zzCustCity',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ zzCustCity: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { zzCustCity } = data
        return generateTextField(
          'zzCustCity',
          zzCustCity,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'zzCustState',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ zzCustState: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { zzCustState } = data
        return generateTextField(
          'zzCustState',
          zzCustState,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'zzCustZip',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ zzCustZip: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { zzCustZip } = data
        return generateTextField(
          'zzCustZip',
          zzCustZip?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'zzCustHomePh',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ zzCustHomePh: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { zzCustHomePh } = data
        return generateTextField(
          'zzCustHomePh',
          zzCustHomePh?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Retail Quantity',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ retailQuantity: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { retailQuantity } = data
        return generateTextField(
          'retailQuantity',
          retailQuantity?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'cTotalSales',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ cTotalSales: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { cTotalSales } = data
        return generateTextField(
          'cTotalSales',
          cTotalSales?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Cost',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ cost: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { cost } = data
        return generateTextField(
          'cost',
          cost?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Article Classification',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ articleClassification: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { articleClassification } = data
        return generateTextField(
          'articleClassification',
          articleClassification?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Account Number',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ accountNumber: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcInvoice, rowIndex: number) => {
        const { accountNumber } = data
        return generateTextField(
          'accountNumber',
          accountNumber?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
  ]
}

export default generateDtcInvoiceColumns
