import { Link, useLocation, useNavigate } from 'react-router-dom'
import styles from './styles.module.css'
import { useEffect } from 'react'
import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../global/components/buttons/secondary-button/SecondaryButton'
import {
  DASHBOARD_KEYS,
  DASHBOARD_NAMES,
  DASHBOARD_PATHS,
} from '../../global/constants/dashboards'

const lastDashboardPathKey = 'lastDashboardPathKey'

function TabChooser() {
  const navigate = useNavigate()

  const activePath = useLocation().pathname

  const noActivePath = activePath === '/dashboards'

  useEffect(() => {
    if (noActivePath) {
      const lastQueuePath = localStorage.getItem(lastDashboardPathKey)
      navigate(lastQueuePath || DASHBOARD_PATHS.futurePercentBooked)
    } else {
      const pathToSave =
        Object.values(DASHBOARD_PATHS).find((path) => path === activePath) ??
        DASHBOARD_PATHS.schedulingPerformance
      localStorage.setItem(lastDashboardPathKey, pathToSave)
    }
  }, [noActivePath, navigate])

  return (
    <div className={styles.buttonsHolder}>
      {Object.values(DASHBOARD_KEYS).map((key) => {
        const dashboardKey = key as keyof typeof DASHBOARD_KEYS
        const isActive = DASHBOARD_PATHS[dashboardKey] === activePath
        const ButtonComponent = isActive ? PrimaryButton : SecondaryButton

        return (
          <Link
            className={styles.link}
            to={DASHBOARD_PATHS[dashboardKey]}
            key={dashboardKey}
          >
            <ButtonComponent
              buttonName={DASHBOARD_NAMES[dashboardKey]}
              onClick={() => {}}
              style={{ width: 'max-content', height: 'max-content' }}
            />
          </Link>
        )
      })}
    </div>
  )
}

export default TabChooser
